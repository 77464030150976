import React, {Layout} from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

const PageContaainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  h1{
    font-family: 'Recoleta';
    font-weight: 900;
  }
  a{
    display: block;
    &:hover{
      text-decoration: none;
    }
  }
`

const PageNotFound = () => (
  <PageContaainer>
    <h1>Page not found</h1>
    <Link to="/">Go Back</Link>
  </PageContaainer>
)

export default PageNotFound
